import BaseAPI from '../api'

const path = 'app/authen'

interface register {
  encryptedData: string;
}
interface cardLocationInterface {
  type: string;
}



export default class AuthenApi extends BaseAPI {

  static registerCustomer(payload: register): Promise<any> {
    return this.api.post(`${path}/register-customer`, payload).then((res) => res)
  }

  static registerBeforeVerify(payload: register): Promise<any> {
    return this.api.post(`${path}/register-before-verify`, payload).then((res) => res)
  }

  static cardLocation(payload: cardLocationInterface): Promise<any> {
    return this.api.post(`/admin/authen/branchRegis/options`, payload).then((res) => res)
  }

  static checkWebVersion(param: any): Promise<any> {
    return this.api.get(`${path}/check-web-version`, { params: { ...param } }).then((res) => res)
  }

}
